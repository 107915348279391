import { useState } from "react";

import { APP_BRANDING_BACKGROUNDS } from "../../../config/data";
import { returnFileToOriginFileObj } from "../../../config/utils";
import ICONS from "../../../config/icons";

import { ImageUpload } from "../../../components";

export default function BackgroundList({ setBackgroundImage }) {
  const [selectedBg, setSelectedBg] = useState(null);

  const onSetFileUrl = (fileInfo) => {
    if (fileInfo) {
      setBackgroundImage(fileInfo);
      setSelectedBg(null);
    }
  };

  const onSelected = (val) => {
    returnFileToOriginFileObj(val).then((result) => {
      setBackgroundImage({
        file: result.originFileObj,
        url: val,
      });
    });

    setSelectedBg(val);
  };

  return (
    <div className="backgrounds-list">
      <ImageUpload
        setFileUrl={onSetFileUrl}
        fileUrl={null}
        width="50px"
        height="50px"
        maskClosable={false}
      />
      {APP_BRANDING_BACKGROUNDS.map((el, index) => (
        <div
          key={el.id}
          onClick={() => onSelected(el.src)}
          className="bg-img"
          style={{ backgroundImage: `url(${el.src})` }}
        >
          {selectedBg == el.src && <span>{ICONS.TRUE}</span>}
        </div>
      ))}
    </div>
  );
}
