import React, { useContext } from "react";
import { BrandingContext } from "../BrandingContext";

import useTranslate from "../../../hooks/translator";

import { ImageUpload } from "../../../components";
import BackgroundList from "./BackgroundList";

export default function Background() {
  const { backgroundImage, setBackgroundImage } = useContext(BrandingContext);
  const translate = useTranslate();

  const onSetFileUrl = (fileInfo) => {
    if (fileInfo) {
      setBackgroundImage(fileInfo);
    }
  };

  return (
    <div className="wrap-background">
      <div>
        <p className="lable">{translate("Choose background from list")}</p>
        <BackgroundList setBackgroundImage={setBackgroundImage} />
      </div>
      <div>
        <p className="lable">{translate("Upload background")}</p>
        <ImageUpload
          setFileUrl={onSetFileUrl}
          fileUrl={backgroundImage?.url || backgroundImage}
          width="537px"
          height="300px"
        />
      </div>
    </div>
  );
}
