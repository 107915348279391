import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

import { Button } from "antd";
import { StopOutlined } from "@ant-design/icons";

// import { convertGradientToHex, returnColor } from "../config/utils";

import "../styles/colors-picker.scss";

export default function ColorsPicker({ color, onChange, disabled = false }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };

  return (
    <div className="colors-picker">
      <Button className="swatch" onClick={handleClick} disabled={disabled}>
        <div className="color" style={{ backgroundColor: color }} />
      </Button>
      {displayColorPicker ? (
        <div className="popover">
          <div className="cover" onClick={() => setDisplayColorPicker(false)} />
          <SketchPicker
            color={
              color?.startsWith("linear-gradient") || !color
                ? "transparent"
                : color
            }
            onChange={onChange}
          />
          <div className="no-color" onClick={() => onChange("")}>
            <StopOutlined />
          </div>
        </div>
      ) : null}
    </div>
  );
}
