export const returnFileToOriginFileObj = async (url) => {
  const segments = url?.split("/");
  const filename = segments?.pop();

  // Fetch the image as a Blob
  const response = await fetch(url);
  const blob = await response.blob();

  // Convert Blob to File object
  const file = new File([blob], filename, {
    type: blob.type,
  });

  // Return the file object with the originFileObj property
  return { originFileObj: file };
};

export const returnColor = (color, percent) => {
  if (color?.startsWith("linear-gradient")) {
    let rgbaPattern = /rgba\(\d{1,3},\d{1,3},\d{1,3},\d*\.?\d+\)/;
    let match = color?.match(rgbaPattern);
    color = match ? match[0] : "";
    return color?.replace(/rgba\(\d+,\d+,\d+,\d+\)/, (match) => {
      return match?.replace(/\d+\)$/, `${percent})`);
    });
  } else if (color?.startsWith("#")) {
    color = color?.replace(/^#/, "");
    // Parse the r, g, b values
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);

    // Calculate the new values, ensuring they don't go below 0
    r = Math.max(0, Math.min(255, r - Math.round((r * percent) / 100)));
    g = Math.max(0, Math.min(255, g - Math.round((g * percent) / 100)));
    b = Math.max(0, Math.min(255, b - Math.round((b * percent) / 100)));

    // Return the darkened color in hex format
    return (
      "#" +
      r.toString(16).padStart(2, "0") +
      g.toString(16).padStart(2, "0") +
      b.toString(16).padStart(2, "0")
    );
  } else if (color?.startsWith("rgb")) {
    return color?.replace(/rgba\(\d+,\d+,\d+,\d+\)/, (match) => {
      return match?.replace(/\d+\)$/, `${percent})`);
    });
  } else {
    return color;
  }
};

function rgbaToHex(rgba) {
  return rgba.replace(
    /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d*)?\)/g,
    (_, r, g, b, a) => {
      const hex = [r, g, b]
        .map((x) => {
          const hexPart = parseInt(x).toString(16);
          return hexPart.length === 1 ? "0" + hexPart : hexPart;
        })
        .join("");

      const alphaHex =
        a && a !== "1"
          ? Math.round(parseFloat(a) * 255)
              .toString(16)
              .padStart(2, "0")
          : "";
      return `#${hex}${alphaHex}`;
    }
  );
}

export function convertGradientToHex(gradient) {
  return gradient.replace(/rgba?\([^)]+\)/g, (match) => rgbaToHex(match));
}

export function getCurrentTime() {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function getFormattedDate() {
  const now = new Date();

  const options = {
    month: "long",
    day: "numeric",
    weekday: "long",
  };

  return now.toLocaleDateString("en-US", options);
}
