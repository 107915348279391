import React, { useContext } from "react";
import { BrandingContext } from "../BrandingContext";

import useTranslate from "../../../hooks/translator";

import ColorsPicker from "../../../components/ColorsPicker";

export default function GeneralColors() {
  const { primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor } =
    useContext(BrandingContext);

  const translate = useTranslate();

  return (
    <div className="wrap-colors">
      <div>
        <p className="lable">{translate("Primary color")}</p>
        <ColorsPicker
          color={primaryColor}
          onChange={(color) => setPrimaryColor(color.hex)}
        />
      </div>
      <div>
        <p className="lable">{translate("Secondary color")}</p>
        <ColorsPicker
          color={secondaryColor}
          onChange={(color) => setSecondaryColor(color.hex)}
        />
      </div>
    </div>
  );
}
