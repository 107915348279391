import React, { useContext } from "react";
import { BrandingContext } from "../BrandingContext";
import { UndoOutlined } from "@ant-design/icons";

export default function ReloadApp() {
  const { menuIconsCollection, menuIconsColor } = useContext(BrandingContext);
  return (
    <div className="reload-app">
      <UndoOutlined
        style={{
          color: menuIconsCollection == 1 ? "#fff" : menuIconsColor,
        }}
      />
      <span
        style={{
          color: menuIconsCollection == 1 ? "#fff" : menuIconsColor,
        }}
      >
        Reload app
      </span>
    </div>
  );
}
