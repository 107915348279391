import React from "react";
import IMAGES from "../../../config/images";

export default function PlayerThree({
  currentIcons,
  playerItemsColor,
  progressBarColor,
}) {
  return (
    <div
      className="player"
      style={{ backgroundImage: `url(${IMAGES.PLAYER_1})` }}
    >
      <div className="wrap" style={{ color: playerItemsColor }}>
        <div className="tree-ply-header">
          <div className="header-wrap">
            <div className="df-row">
              {currentIcons?.back}
              Media name
            </div>
          </div>
        </div>

        <div className="tree-ply-footer">
          <div className="footer-wrap">
            <div className="play">
              <span>{currentIcons?.backward}</span>
              <span style={{ transform: "scale(1.5)" }}>
                {currentIcons?.play}
              </span>
              <span>{currentIcons?.forward}</span>
            </div>

            <div className="settings">
              <span>{currentIcons?.skip}</span>
              <span>{currentIcons?.subtitles}</span>
            </div>

            <div className="progress-wrap">
              <div style={{ color: "#c3bdbd" }}>00:20:02</div>
              <div
                className="tree-ply-progress"
                style={{ backgroundColor: "#e4e7e587" }}
              >
                <div
                  className="progress"
                  style={{ backgroundColor: progressBarColor }}
                >
                  <div
                    className="point"
                    style={{ backgroundColor: progressBarColor }}
                  />
                </div>
              </div>
              <div style={{ color: "#c3bdbd" }}>01:35:05</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mask"></div>
    </div>
  );
}
