import React from "react";
import IMAGES from "../../../config/images";

export default function Logo({ logo, logoSize }) {
  return (
    <img
      src={logo?.url || logo}
      onError={(e) => (e.target.src = IMAGES.ERROR)}
      alt=""
      className="tv-logo"
      style={{ width: logoSize ? `${logoSize * 5}px` : "137px" }}
    />
  );
}
