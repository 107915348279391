import React, { useContext } from "react";
import { Radio } from "antd";

import { BrandingContext } from "../BrandingContext";

import { PLAYER_ICONS_COLLECTION } from "../../../config/data";
import useTranslate from "../../../hooks/translator";
import ColorsPicker from "../../../components/ColorsPicker";

import PlayerFirst from "../components/PlayerFirst";
import PlayerSecond from "../components/PlayerSecond";
import PlayerThree from "../components/PlayerThree";

import "../styles/player.scss";

export default function PlayerApp() {
  const {
    playerVersion,
    setPlayerVersion,
    playerIconsCollection,
    setPlayerIconsCollection,
    playerItemsColor,
    setPlayerItemsColor,
    progressBarColor,
    setProgressBarColor,
  } = useContext(BrandingContext);

  const translate = useTranslate();

  const returnSelectedPlayer = () => {
    switch (playerVersion) {
      case 2:
        return (
          <PlayerSecond
            currentIcons={PLAYER_ICONS_COLLECTION[playerIconsCollection - 1]}
            playerItemsColor={playerItemsColor}
            progressBarColor={progressBarColor}
          />
        );
      case 3:
        return (
          <PlayerThree
            currentIcons={PLAYER_ICONS_COLLECTION[playerIconsCollection - 1]}
            playerItemsColor={playerItemsColor}
            progressBarColor={progressBarColor}
          />
        );
      default:
        return (
          <PlayerFirst
            currentIcons={PLAYER_ICONS_COLLECTION[playerIconsCollection - 1]}
            playerItemsColor={playerItemsColor}
            progressBarColor={progressBarColor}
          />
        );
    }
  };

  return (
    <div className="page-player">
      <div className="collections-list">
        <div>
          <p className="panel-title">{translate("Choose player")}</p>
          <Radio.Group
            value={playerVersion}
            className="radio-group"
            onChange={(e) => setPlayerVersion(e.target.value)}
          >
            <Radio value={1}>{translate("Player")} 1</Radio>
            <Radio value={2}>{translate("Player")} 2</Radio>
            <Radio value={3}>{translate("Player")} 3</Radio>
          </Radio.Group>
        </div>

        <div>
          <p className="panel-title">{translate("Choose items color")}</p>
          <ColorsPicker
            color={playerItemsColor}
            onChange={(color) => setPlayerItemsColor(color.hex)}
          />
        </div>

        <div>
          <p className="panel-title">
            {translate("Choose progress bar color")}
          </p>
          <ColorsPicker
            color={progressBarColor}
            onChange={(color) => setProgressBarColor(color.hex)}
          />
        </div>

        <div>
          <p className="panel-title">{translate("Choose your icons")}</p>

          <Radio.Group
            value={playerIconsCollection}
            onChange={(e) => setPlayerIconsCollection(e.target.value)}
            className="radio-group"
          >
            {PLAYER_ICONS_COLLECTION?.map((item, index) => (
              <Radio value={index + 1} key={index}>
                <p>
                  {translate("Collection")} {index + 1}
                </p>
                <div className="collection">
                  {item.back}
                  {item.pause}
                  {item.play}
                  {item.backward}
                  {item.forward}
                  {item.skip}
                  {item.subtitles}
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>

      <div>{returnSelectedPlayer()}</div>
    </div>
  );
}
