import React from "react";
import IMAGES from "../../../config/images";

export default function PlayerSecond({
  currentIcons,
  playerItemsColor,
  progressBarColor,
}) {
  return (
    <div
      className="player"
      style={{ backgroundImage: `url(${IMAGES.PLAYER_1})` }}
    >
      <div className="wrap" style={{ color: playerItemsColor }}>
        <div className="header-second-player">
          <div className="header-wrap">
            <div className="df-row">
              {currentIcons?.back}
              Media name
            </div>
          </div>
        </div>

        <div className="player-footer-sp">
          <div className="footer-wrap">
            <div className="df-sb">
              <span style={{ color: "#fff" }}>00:20:02</span>
              <div
                className="progress-wrap-sp"
                style={{ backgroundColor: "#e4e7e587" }}
              >
                <div
                  className="progress"
                  style={{ backgroundColor: progressBarColor }}
                >
                  <div
                    className="point"
                    style={{
                      boxShadow: `0px 0px 9px 1px ${progressBarColor}`,
                      backgroundColor: progressBarColor,
                    }}
                  ></div>
                </div>
              </div>
              <span style={{ color: "#fff" }}>01:35:05</span>
            </div>

            <div className="footer-actions-sp">
              <div>{currentIcons?.subtitles}</div>
              <div>{currentIcons?.backward}</div>
              <div>{currentIcons?.play}</div>
              <div>{currentIcons?.forward}</div>
              <div>{currentIcons?.skip}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mask"></div>
    </div>
  );
}
