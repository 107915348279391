import LOGO from "../images/logo.png";

import TV_BACKGROUND from "../images/brending_page/bg.png";
import TV_LOGO from "../images/brending_page/logo.png";

import CREDIT_BTN from "../images/Credit.png";

import MOVIE_1 from "../images/brending_page/img/films/Green_Book.jpg";
import MOVIE_2 from "../images/brending_page/img/films/2.Avatar.jpeg";
import MOVIE_3 from "../images/brending_page/img/films/3.Black Widow.jpg";
import MOVIE_4 from "../images/brending_page/img/films/4.Avengers.jpg";
import MOVIE_5 from "../images/brending_page/img/films/5.Spider-Man.jpg";
import MOVIE_6 from "../images/brending_page/img/films/no film.png";
import MOVIE_7 from "../images/brending_page/img/films/film.jpg";
import MOVIE_8 from "../images/brending_page/img/films/Old Guard.png";
import MOVIE_9 from "../images/brending_page/img/films/no film.png";
import MOVIE_10 from "../images/brending_page/img/films/Red notice.jpg";
import MOVIE_11 from "../images/brending_page/img/films/Bohemian Rapsody.png";
import MOVIE_12 from "../images/brending_page/img/films/Star Is Born.png";
import MOVIE_13 from "../images/brending_page/img/films/6.Bullet Train.jpg";
import MOVIE_14 from "../images/brending_page/img/films/Ambulance.png";
import MOVIE_15 from "../images/brending_page/img/films/Old Resort.jpg";

import CHANNEL_4 from "../images/brending_page/img/channels/BBC-News.jpg";
import CHANNEL_2 from "../images/brending_page/img/channels/channel_2.jpg";
import CHANNEL_3 from "../images/brending_page/img/channels/channel_3.jpg";
import CHANNEL_1 from "../images/brending_page/img/channels/4.CNN_International.svg.png";
import CHANNEL_5 from "../images/brending_page/img/channels/5.Fox TV.png";
import CHANNEL_6 from "../images/brending_page/img/channels/6.CCTV News.png";
import CHANNEL_7 from "../images/brending_page/img/channels/7.CBS News.jpg";

// social icons
import MESSAGE from "../images/message.png";
import JOIN_COMMUNITY from "../images/add-group.png";

// languages
import EN from "../images/flags/en.png";
import PT from "../images/flags/pt.png";
import DE from "../images/flags/de.png";
import ES from "../images/flags/es.png";
import FR from "../images/flags/fr.png";
import IT from "../images/flags/it.png";
import SQ from "../images/flags/sq.png";
import TR from "../images/flags/tr.png";
import ZH from "../images/flags/zh.png";

import SW from "../images/flags/sw.png";
import AM from "../images/flags/am.png";
import OM from "../images/flags/om.png";
import IG from "../images/flags/ig.png";
import SO from "../images/flags/so.png";
import HA from "../images/flags/ha.png";
import MNK from "../images/flags/mnk.png";
import FF from "../images/flags/ff.png";
import YO from "../images/flags/yo.png";

// onError
import MAN from "../images/man.png";
import ERROR from "../images/err.png";

import MASTER_CARD from "../images/visa-master.png";
import PAY_WITH_CARD from "../images/pay_with_card.png";

import VPN from "../images/vpn.png";

import SUBTRACT from "../images/subtract.png";

import GUIDE_SECTOR from "../images/guide-sector.jpg";

//platforms
import PLATFORM_BG from "../images/platforms/platform-bg.png";
import ANDROID_TV from "../images/platforms/androidTv.svg";
import TIZEN from "../images/platforms/tizen.svg";
import WEBOS from "../images/platforms/webos.svg";
import FIRE_TV from "../images/platforms/fireTv.svg";
import TV_OS from "../images/platforms/tvos.svg";
import ROKU from "../images/platforms/roku.svg";
import VIDDA from "../images/platforms/vidda.svg";
import FOXXUM from "../images/platforms/foxxum.svg";
import ZESSEN from "../images/platforms/zeas.svg";
import VIZIO from "../images/platforms/vizio.svg";
import IOS from "../images/platforms/ios.svg";
import ANDROID_MOBILE from "../images/platforms/android-mobile.svg";

// app branding backgrounds
import BRANDING_BG_1 from "../images/brending_page/app-background/1.png";
import BRANDING_BG_2 from "../images/brending_page/app-background/2.png";
import BRANDING_BG_4 from "../images/brending_page/app-background/4.png";
import BRANDING_BG_5 from "../images/brending_page/app-background/5.png";
import BRANDING_BG_6 from "../images/brending_page/app-background/6.png";
import BRANDING_BG_7 from "../images/brending_page/app-background/7.png";
import BRANDING_BG_8 from "../images/brending_page/app-background/8.png";
import BRANDING_BG_9 from "../images/brending_page/app-background/9.png";
import BRANDING_BG_10 from "../images/brending_page/app-background/10.png";
import BRANDING_BG_11 from "../images/brending_page/app-background/11.png";
import BRANDING_BG_12 from "../images/brending_page/app-background/12.png";
import BRANDING_BG_13 from "../images/brending_page/app-background/13.png";
import BRANDING_BG_15 from "../images/brending_page/app-background/15.png";

import BANNER_AD_DEFAULT from "../images/dark-banner.jpg";
import PLAY from "../images/play.png";

// Branding menu page
import MENU_PAGE_V2_BG from "../images/brending-menu-page/menu-bg.jpg";
import MENU_PAGE_V2_LIVE from "../images/brending-menu-page/liveTv.png";
import MENU_PAGE_V2_MOVIES from "../images/brending-menu-page/movies.png";
import MENU_PAGE_V2_SERIES from "../images/brending-menu-page/series.png";
import MENU_PAGE_V2_SETTINGS from "../images/brending-menu-page/settings.png";

import TOP_MOVIE_1 from "../images/brending-menu-page/m-1.jpg";
import TOP_MOVIE_2 from "../images/brending-menu-page/m-2.jpg";
import TOP_MOVIE_3 from "../images/brending-menu-page/m-3.jpg";
import TOP_MOVIE_4 from "../images/brending-menu-page/m-4.jpg";

import TOP_SERIE_1 from "../images/brending-menu-page/s-1.jpg";
import TOP_SERIE_2 from "../images/brending-menu-page/s-2.jpg";
import TOP_SERIE_3 from "../images/brending-menu-page/s-3.jpg";
import TOP_SERIE_4 from "../images/brending-menu-page/s-4.jpg";
import PLAYER_1 from "../images/player_1.jpg";

import ROUND from "../images/brending-menu-page/round.png";
import QUADRATE from "../images/brending-menu-page/quadrate.png";
import HALFCIRCLE from "../images/brending-menu-page/halfcircle.png";

const IMAGES = {
  // a
  ANDROID_TV,
  ANDROID_MOBILE,
  AM,
  // b
  BRANDING_BG_1,
  BRANDING_BG_2,
  BRANDING_BG_4,
  BRANDING_BG_5,
  BRANDING_BG_6,
  BRANDING_BG_7,
  BRANDING_BG_8,
  BRANDING_BG_9,
  BRANDING_BG_10,
  BRANDING_BG_11,
  BRANDING_BG_12,
  BRANDING_BG_13,
  BRANDING_BG_15,
  BANNER_AD_DEFAULT,
  // c
  CHANNEL_1,
  CHANNEL_2,
  CHANNEL_3,
  CHANNEL_4,
  CHANNEL_5,
  CHANNEL_6,
  CHANNEL_7,
  CREDIT_BTN,
  // d
  DE,
  // e
  EN,
  ES,
  ERROR,
  // f
  FR,
  FIRE_TV,
  FOXXUM,
  FF,
  // g
  GUIDE_SECTOR,
  // h
  HALFCIRCLE,
  HA,

  // i
  IT,
  IOS,
  IG,

  // j
  JOIN_COMMUNITY,
  // k
  // l
  LOGO,
  // m
  MENU_PAGE_V2_BG,
  MENU_PAGE_V2_LIVE,
  MENU_PAGE_V2_MOVIES,
  MENU_PAGE_V2_SERIES,
  MENU_PAGE_V2_SETTINGS,
  MASTER_CARD,
  MAN,
  MOVIE_1,
  MOVIE_2,
  MOVIE_3,
  MOVIE_4,
  MOVIE_5,
  MOVIE_6,
  MOVIE_7,
  MOVIE_8,
  MOVIE_9,
  MOVIE_10,
  MOVIE_11,
  MOVIE_12,
  MOVIE_13,
  MOVIE_14,
  MOVIE_15,
  MESSAGE,
  MNK,

  // n
  // o
  OM,

  // p
  PT,
  PAY_WITH_CARD,
  PLATFORM_BG,
  PLAY,
  PLAYER_1,
  // q
  QUADRATE,
  // r
  ROUND,
  ROKU,
  // s
  SUBTRACT,
  SQ,
  SW,
  SO,

  // t
  TOP_MOVIE_1,
  TOP_MOVIE_2,
  TOP_MOVIE_3,
  TOP_MOVIE_4,
  TOP_SERIE_1,
  TOP_SERIE_2,
  TOP_SERIE_3,
  TOP_SERIE_4,
  TV_BACKGROUND,
  TV_LOGO,
  TIZEN,
  TV_OS,
  TR,
  // u
  // v
  VPN,
  VIDDA,
  VIZIO,
  // w
  WEBOS,
  // x
  // y
  // z
  ZESSEN,
  ZH,
  YO,
};

export default IMAGES;
