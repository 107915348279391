import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import { setIsRefreshBillingHistory } from "./store/features/profileSlice.js";

// import socketIOClient from "socket.io-client";

import LayoutComponent from "./layout/LayoutComponent.jsx";

import PATHS from "./config/paths.js";

// import { API_HOST } from "./config/config.js";

import { modifyClassStyle, APP_FOR } from "./config/themesConfigs.js";

import LoginPage from "./pages/login/LoginPage.jsx";
import DashboardPage from "./pages/dashboard/DashboardPage.jsx";
import BillingPage from "./pages/billing-history/BillingPage.jsx";
import DevicesPage from "./pages/devices/DevicesPage.jsx";
import DeviceInfo from "./pages/devices/DeviceInfo.jsx";
import SubresellersPage from "./pages/subresellers/Page.jsx";
import DNSPage from "./pages/dns/DNSPage.jsx";
// import BannerAdPage from "./pages/banner-ad/BannerAdPage.jsx";
import WelcomePage from "./pages/WelcomPage.jsx";
import SettingsPage from "./pages/settings/Page.jsx";
import ErrorLogsPage from "./pages/error-logs/ErrorLogs.jsx";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage.jsx";
import ResetPasswordPage from "./pages/login/ResetPasswordPage.jsx";
import BuyCreditPage from "./pages/buy-credit/BuyCreditPage.jsx";
// import NotificationPage from "./pages/notifications/NotificationPage.jsx";

import ViewMoreLivesStatistics from "./pages/dashboard/components/ViewMoreLivesStatistics";
import ViewMoreMoviesStatistics from "./pages/dashboard/components/ViewMoreMoviesStatistics.jsx";
import ViewMoreSeriesStatistics from "./pages/dashboard/components/ViewMoreSeriesStatistics.jsx";

import BrandingPage from "./pages/branding-app/BrandingPage.jsx";
import NotFound from "./components/NotFound.jsx";

import "antd/dist/antd.css";
import "./App.scss";

export let socket = null;

function App() {
  // const token = localStorage.getItem("TOKEN");

  // const dispatch = useDispatch();

  const navigate = useNavigate();
  const { profile, isRefreshBillingHistory } = useSelector(
    (state) => state.profile
  );

  const fromWeb = window.location.hash;

  useEffect(() => {
    modifyClassStyle();
  }, []);

  useEffect(() => {
    if (fromWeb == "#/login?signin") {
      localStorage.clear();
      navigate("/");
    }
  }, [fromWeb]);

  // useEffect(() => {
  //   if (token) {
  //     socket = socketIOClient(API_HOST, {
  //       auth: {
  //         token: "Bearer " + token,
  //         type: "provider",
  //       },
  //     });

  //     socket.on("connect", () => {
  //       console.log("connect")
  //     });

  //     socket.on("payment_credit_success", () => {
  //       dispatch(setIsRefreshBillingHistory(true));
  //       console.log("credit success")
  //     });

  //     return () => {
  //       if (socket) {
  //         socket.disconnect();
  //         console.log("disconnect")
  //       }
  //     };
  //   }
  // }, [token]);

  function showBilling() {
    if (profile?.is_seller) {
      return true;
    }
    if (profile?.parent_id && profile?.status == "NEED_BUY_PARTNER_CREDITS") {
      return true;
    }
    return false;
  }

  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path={PATHS.LOGIN} element={<LoginPage />} />
      <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={PATHS.RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route path="*" element={<NotFound />} />

      <Route path={PATHS.DASHBOARD} element={<LayoutComponent />}>
        <Route index element={<DashboardPage />} />
        <Route path={PATHS.DEVICES_PAGE} element={<DevicesPage />} />
        <Route path={PATHS.DEVICE_INFO} element={<DeviceInfo />} />
        {(!profile?.parent_id ||
          (profile?.parent_id &&
            profile?.status === "NEED_BUY_PARTNER_CREDITS")) && (
          <Route path={PATHS.SERVER_HOST} element={<DNSPage />} />
        )}
        {((!profile?.parent_id && !profile?.is_partner) || showBilling()) && (
          <Route path={PATHS.BILLING_PAGE} element={<BillingPage />} />
        )}
        {profile?.is_partner || (profile?.parent_id && !profile?.is_partner) ? (
          <>
            <Route path={PATHS.SUBRESELLERS} element={<SubresellersPage />} />
          </>
        ) : (
          <Route path="*" element={<NotFound />} />
        )}

        {/* {profile?.is_partner && profile?.app_name && (
          <Route path={PATHS.BANNER_AD} element={<BannerAdPage />} />
        )} */}
        {/* <Route path={PATHS.NOTIFICATION} element={<NotificationPage />} /> */}
        <Route path={PATHS.LIVES} element={<ViewMoreLivesStatistics />} />
        <Route path={PATHS.MOVIES} element={<ViewMoreMoviesStatistics />} />
        <Route path={PATHS.SERIES} element={<ViewMoreSeriesStatistics />} />
        <Route path={PATHS.SETTINGS} element={<SettingsPage />} />
        <Route path={PATHS.ERROR_LOGS} element={<ErrorLogsPage />} />
        <Route path={PATHS.BUY_CREDIT} element={<BuyCreditPage />} />
        {APP_FOR == "xcloud" && (
          <Route path={PATHS.APP_BRANDING} element={<BrandingPage />} />
        )}
      </Route>
    </Routes>
  );
}

export default App;
