import React from "react";
import useTranslate from "../../hooks/translator";
import { APP_VERSION } from "../../config/config";
import ICONS from "../../config/icons";

export default function SidebarFooter() {
  const translate = useTranslate();
  return (
    <div className="sidebar-footer">
      <span style={{ marginLeft: "-5px" }}>
        {ICONS.VERSION} {translate("App version")}:
      </span>
      <span>{APP_VERSION}</span>
    </div>
  );
}
