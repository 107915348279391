import React, { useContext } from "react";
import { InputNumber } from "antd";

import { BrandingContext } from "../BrandingContext";

import useTranslate from "../../../hooks/translator";

import { ImageUpload } from "../../../components";

export default function UploadLogo() {
  const { logo, setLogo, logoSize, setLogoSize } = useContext(BrandingContext);

  const translate = useTranslate();

  return (
    <div className="wrap-logo">
      <div style={{ minWidth: "104px", height: "104px" }}>
        <p className="lable">{translate("Upload logo")}</p>
        <ImageUpload
          setFileUrl={setLogo}
          fileUrl={logo?.url || logo}
          width="104px"
          height="104px"
        />
      </div>
      <div>
        <p className="lable">{translate("Resize logo")}</p>
        <InputNumber value={logoSize} onChange={(val) => setLogoSize(val)} />
      </div>
    </div>
  );
}
