import React, { useContext } from "react";
import { BrandingContext } from "../BrandingContext";

import { menuV3MenuIconsCollection } from "../../../config/data";
import { ICONS } from "../../../config";
import { getCurrentTime, getFormattedDate } from "../../../config/utils";

import Logo from "./Logo";

import "../styles/menu-v3-page.scss";

export default function MenuV3() {
  const {
    backgroundImage,
    logo,
    logoSize,
    menuIconsColor,
    menuItemsColor,
    menuItemsActiveColor,
    menuIconsCollection,
    menuItemsBoxForm,
  } = useContext(BrandingContext);

  const returnMenuItemsBoxForm = () => {
    switch (menuItemsBoxForm) {
      case "round":
        return "50%";
      case "quadrate":
        return "0px";
      case "halfcircle":
        return "15px";
      default:
        return "50%";
    }
  };

  return (
    <div
      className="menu-three-page"
      style={{
        backgroundImage: `url(${backgroundImage?.url || backgroundImage})`,
        backgroundColor: "#000",
      }}
    >
      <div className="left-wrap">
        <div className="logo">
          <Logo logo={logo} logoSize={logoSize} />
        </div>

        <div
          className="weather-date"
          style={{
            color: "#fdfdfd",
            textShadow: `0px 0px 19.51px #FFFFFF40`,
          }}
        >
          {/* <div className="icon-temp"> */}
          {/* <span>{ICONS.WEATHER}</span>
            <span>23{ICONS.C}</span> */}
          {/* </div> */}
          <div className="time">{getCurrentTime() || "12:51"}</div>
          <div className="date">
            {getFormattedDate() || "November 11, Monday"}
          </div>

          <div className="reload-app">
            {ICONS.RELOAD}
            <span>Reload app</span>
          </div>
        </div>
      </div>

      <div
        className="right-wrap"
        style={{
          justifyContent: menuItemsBoxForm == "round" ? "center" : "flex-end",
        }}
      >
        <div
          className={`${
            menuItemsBoxForm == "round" ? "icons-round-wrap" : "icons-wrap"
          }`}
        >
          {menuV3MenuIconsCollection[menuIconsCollection - 1] ? (
            Object?.entries(
              menuV3MenuIconsCollection[menuIconsCollection - 1]
            )?.map(([key, value]) => (
              <div
                key={key}
                className={`${
                  menuItemsBoxForm == "round" ? "menu-icon_round" : "menu-icon"
                }`}
                style={{
                  backgroundColor: "#010101",
                  color: menuIconsColor || "#FDFDFD",
                  tetxShadow: `0px 0px 7.34px 0px #FFFFFF40`,
                  border: `1px solid ${
                    key == "live"
                      ? menuItemsActiveColor
                      : menuItemsColor || "#505050"
                  } `,
                  borderRadius: returnMenuItemsBoxForm(),
                }}
              >
                <div className={`bg-icon  bg-${key}`}>{value}</div>
                <span
                  className="title"
                  style={{
                    color: menuIconsColor || "#FDFDFD",
                  }}
                >
                  {key == "live" ? "LIVE TV" : key}
                </span>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
