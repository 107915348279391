import React from "react";
import UploadLogo from "../components/UploadLogo";
import GeneralColors from "../components/GeneralColors";
import Background from "../components/Background";

export default function GeneralInfo() {
  return (
    <>
      <div className="horizontal">
        <UploadLogo />
        <GeneralColors />
      </div>
      <div className="mtop">
        <Background />
      </div>
    </>
  );
}
