import React from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedLanguage } from "../store/features/profileSlice";

import { languageOptions } from "../config/data";
import "../styles/languages.scss";

export default function Languages() {
  const dispatch = useDispatch();
  const { selectedLanguage } = useSelector((state) => state.profile);

  const changeLanguage = (leng) => {
    dispatch(setSelectedLanguage(leng));
    localStorage.setItem("leng", leng);
  };

  return (
    <div className="languages-select">
      <Select
        value={selectedLanguage}
        onChange={(lng) => changeLanguage(lng)}
        options={languageOptions}
        className="language-select"
      />
    </div>
  );
}
